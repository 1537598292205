import {
  ConnexionResponse,
  MapInfo,
  RoslibService,
  RosObject,
  Topic,
} from '@lelab31/ngx-rodotic';
import { Robot } from '../../../api/models/robot';
import { Map } from '../../../api/models/map';
import { InputBatteryMessage } from '../ros/input/input-battery-message';
import { InputInchargeMessage } from '../ros/input/input-incharge-message';
import { InputSystemStateMessage } from '../ros/input/input-system-state-message';
import { RobotState } from '../../enums/robot-state.enum';
import { RobotLight } from '../../enums/robot-light.enum';
import { InputLightMessage } from '../ros/input/input-light-message';
import { OutputLightMessage } from '../ros/output/output-light-message';
import { RobotSpeed } from '../../enums/robot-speed.enum';
import { InputSpeedMessage } from '../ros/input/input-speed-message';
import { environment } from '../../../../environments/environment';
import { MapConfigurationAdapter } from './map-configuration-adapter';
import { IEntityAdapter } from '../../../components/admin/models/i-entity-adapter';
import { Strategy } from '../../../api/models/strategy';
import { StrategyName } from '../../enums/strategy-name.enum';
import { StrategyRequest } from '../../enums/strategy-request.enum';
import { Motion } from '../../enums/motion.enum';
import { InputErrorMessage } from '../ros/input/input-error-message';
import { RobotError } from '../../enums/robot-error.enum';
import { OutputSpeedMessage } from '../ros/output/output-speed-message';
import { OutputForcedMotionMessage } from '../ros/output/output-forced-motion-message';
import { OutputInhibitStrategyMessage } from '../ros/output/output-inhibit-strategy-message';
import { OutputForcedDepartureMessage } from '../ros/output/output-forced-departure-message';
import { RobotAlert } from '../../enums/robot-alert.enum';
import { InputAlertMessage } from '../ros/input/input-alert-message';
import { MultiState } from '../../enums/multi-state.enum';
import { InputBieMessage } from '../ros/input/input-bie-message';
import { OutputExplorationMapMessage } from '../ros/output/output-exploration-map-message';
import { OutputAbortExplorationMessage } from '../ros/output/output-abort-exploration-message';
import { InputPositionMessage } from '../ros/input/input-position-message';
import { Pose } from 'roslib';
import { BehaviorSubject, filter, Observable, Subject } from 'rxjs';
import { StrategyAdapter } from './strategy-adapter';
import { PatrolCheckpoint } from '../../../api/models/patrol-checkpoint';
import { Checkpoint } from '../../../api/models/checkpoint';
import { MapConfiguration } from '../../../api/models/map-configuration';
import { CheckpointDTO } from '../ros/dto/checkpoint-dto';
import { Utils } from '../../utils/utils';
import { CheckpointTransformer } from '../ros/transformer/checkpoint-transformer';
import { OutputSetCheckpointMessage } from '../ros/output/output-set-checkpoint-message';
import { StrategyDTO } from '../ros/dto/strategy-dto';
import { StrategyTransformer } from '../ros/transformer/strategy-transformer';
import { RobotTransformer } from '../ros/transformer/robot-transformer';
import { StrategyMode } from '../../enums/strategy-mode.enum';
import { OutputSetStrategyMessage } from '../ros/output/output-set-strategy-message';
import { OutputLoadMapMessage } from '../ros/output/output-load-map-message';
import { OutputDesactivateMapMessage } from '../ros/output/output-desactivate-map-message';
import { InputMapCreationStatusMessage } from '../ros/input/input-map-creation-status-message';
import { Logger } from '../interfaces/logger';
import { RobotDTO } from '../ros/dto/robot-dto';
import { InputBie } from '../interfaces/input-bie';
import { OutputEmergencyMessage } from '../ros/output/output-emergency-message';
import { InputAccessoriesStatusMessage } from '../ros/input/input-accessories-status-message';
import { OutputFireMessage } from '../ros/output/output-fire-message';
import { OutputArmingMessage } from '../ros/output/output-arming-message';

export type videoFormat = 'small' | 'medium';

export class RobotAdapter implements RosObject, IEntityAdapter {
  /**
   * Identifiant du robot
   */
  public id: number | undefined;

  /**
   * Nom du robot
   */
  public name: string | undefined;

  /**
   * Identifiant du robot
   */
  public uid: string;

  /**
   * Url ros du robot
   */
  public url: string;

  /**
   * Url de la vidéo
   */
  public videoUrl: string;

  /**
   * Etat de la connection
   */
  public connected = false;

  /**
   * Niveau de la batterie
   */
  public battery: number | undefined;

  /**
   * Etat de charge de la batterie
   */
  public incharge = false;

  /**
   * Etat de l'accessoire
   */
  public accessoriesStatus = 0;

  /**
   * Statu Armé de l'accessoire
   */
  public isarming = false;

  /**
   * Statu Mise à feu de l'accessoire
   */
  public isfire = false;

  /**
   * Etat du robot
   */
  public state: RobotState | undefined;

  /**
   * Indique l'erreur
   */
  public errorState: RobotError = RobotError.NO_ERROR;

  /**
   * Indique l'alerte
   */
  public alertState: RobotAlert | undefined;

  /**
   * Indique l'alerte précédente
   */
  public prevAlertState: RobotAlert | undefined;

  /**
   * Liste des alertes
   */
  public alerts: RobotAlert[] = [];

  /**
   * Etat de la lumière
   */
  public lightState: RobotLight = RobotLight.OFF;

  /**
   * Etat de la configuration
   */
  public configurationState: MultiState | undefined;

  /**
   * Etat de la patrouille
   */
  public patrolState: MultiState | undefined;

  /**
   * Etat du BIE
   */
  public bieState: MultiState = MultiState.ERROR;

  /**
   * Valeurs des entrée du BIE
   */
  public bieValues: InputBie[] = [];

  /**
   * Mode de la vitesse
   */
  public speedMode: RobotSpeed | undefined;

  /**
   * Topic permettant de voir si le robot est connecté
   */
  public keepaliveTopic: Topic;

  /**
   * Information sur la carte
   */
  public mapInfo: MapInfo | undefined;

  /**
   * Configuration active
   */
  public activeMapConfigurationAdapter: MapConfigurationAdapter | undefined;

  /**
   * Rotation restantes
   */
  public remainingRotation: number | undefined;

  /**
   * Fin de patrouille en minutes
   */
  public endOfPatrolmin: number | undefined;
  
  /**
   * Fin de patrouille en secondes
  */
  public endOfPatrolsec: number | undefined;

  /**
   * Statu de l'animation manual_control (off:0/on:1/ending:2)
  */
  public ismanualcontrol: number | undefined;

  /**
   * Stratégie en cours
   */
  public currentStrategyAdapter$: BehaviorSubject<StrategyAdapter | null> =
    new BehaviorSubject<StrategyAdapter | null>(null);

  /**
   * Point de patrouille en cours
   */
  public currentGoal$: BehaviorSubject<PatrolCheckpoint | null> =
    new BehaviorSubject<PatrolCheckpoint | null>(null);

  public currentGoal: PatrolCheckpoint | undefined;

  /**
   * Type de stratégie en cours
   */
  public currentStrategyType: StrategyName | null = null;

  /**
   * Position courante
   */
  public currentPosition$: Subject<Pose> = new Subject<Pose>();

  /**
   * Timer pour l'éétat du BIE
   *
   * @private
   */
  private bieTimer: NodeJS.Timeout | undefined;

  /**
   * Logs
   *
   * @private
   */
  public logs: Logger[] = [];

  /**
   * Indique si le robot est dans la niche
   */
  public inniche: boolean | undefined;

  /**
   * Est éditable
   */
  public editable = true;

  /**
   * Est supprimable
   */
  public removable = true;

  constructor(
    public robot: Robot,
    private roslibService: RoslibService,
    private videoFormat: videoFormat = 'medium'
  ) {
    this.id = robot.id;
    this.name = robot.name;
    this.uid = robot.id.toString();
    this.url = `wss://${environment.webUrl}:${robot.port}`;
    //this.url = `ws://${robot.ip}:${robot.port}`;

    this.bieValues = [
      { id: -1, x: 0, y: false },
      { id: -1, x: 1, y: false },
      { id: -1, x: 2, y: false },
      { id: -1, x: 3, y: false },
    ];

    if (robot.activeMapConfiguration !== null) {
      this.activeMapConfigurationAdapter = new MapConfigurationAdapter(
        robot.activeMapConfiguration
      );
    }
    let videoUrl = '';

    if (this.robot.id === 134) {
      videoUrl = `rtsp://192.168.0.14:8554/unicast`;
    } else {
      videoUrl = `rtsp://${this.robot?.onvifUsername}:${this.robot?.onvifPassword}@${this.robot?.onvifHostname}:${this.robot?.onvifRtspPort}/alf31`;
    }
    this.videoUrl = `${environment.streamUrl}?url=${encodeURIComponent(
      videoUrl
    )}&channel=${this.id}&format=${this.videoFormat}`;

    this.keepaliveTopic = {
      name: robot.keepaliveTopicName,
      type: robot.keepaliveTopicType,
    };


    // Initialisation des paramètres de la carte.
    this.setMapInfo(this.robot);
    
    // Init de l'etat de la configuration du robot. 
    this.configuration();
    
    // Initialisation de la connexion.
    this.connect();

  }

  /**
   * Initialisation des subscribers
   *
   * @private
   */
  private initSubscribers() {
    this.voltage();
    this.charge();
    this.light();
    this.speed();
    this.systemState();
    this.error();
    this.alert();
    this.position();
    this.bie();
    this.mapCreationStatus();
    this.getAccessoriesStatus();
  }

  public setMapInfo(robot: Robot) {
    if (
      robot.activeMap &&
      robot.activeMap.image &&
      robot.activeMap.resolution &&
      robot.activeMap.origin &&
      robot.activeMap.height &&
      robot.activeMap.width
    ) {
      this.mapInfo = {
        dataOrUrl: environment.host + robot.activeMap.image,
        height: robot.activeMap.height,
        width: robot.activeMap.width,
        resolution: robot.activeMap.resolution,
        origin: robot.activeMap.origin,
      };
    }
  }

  /**
   * Retourne le statut de la connection
   *
   * @private
   */
  private connect(): void {
    this.roslibService.connect([this]);

    this.roslibService.onConnectionStatus?.subscribe(
      (connexionResponse: ConnexionResponse) => {
        if (this.uid === connexionResponse.id) {

          // Si le status connected du robot passe de false à true, alors on initialise les subscribers.
          if(this.connected === false && connexionResponse.status === true){
            this.initSubscribers();
          }

          this.connected = connexionResponse.status;
          this.log(
            MultiState.INFO,
            'robot.connection.' + connexionResponse.status
          );
        }
      }
    );
  }

  /**
   * Indique si la batterie est en charge
   */
  private charge(): void {
    this.roslibService
      .subscribeTopic<InputInchargeMessage>(
        [this],
        '/core2/plugged',
        'std_msgs/Int16'
      )
      .subscribe((message: InputInchargeMessage) => {
        if (this.uid === message.id) {
          this.incharge = message.data;
        }
      });
  }

  /**
   * Retourne le niveau de la batterie
   */
  private voltage(): void {
    this.roslibService
      .subscribeTopic<InputBatteryMessage>(
        [this],
        '/core2/battery',
        'sensor_msgs/BatteryState'
      )
      .subscribe((message: InputBatteryMessage) => {
        if (this.uid === message.id) {
          this.battery = Math.round(message.percentage);
        }
      });
  }

  /**
   * Etat de la lumière
   */
  private light(): void {
    this.roslibService
      .subscribeTopic<InputLightMessage>(
        [this],
        '/Status/Light',
        'std_msgs/Int16'
      )
      .subscribe((message: InputLightMessage) => {
        if (this.uid === message.id) {
          this.lightState = message.data === 0 ? RobotLight.OFF : RobotLight.ON;
        }
      });
  }

  /**
   * Mode de la vitesse
   */
  private speed(): void {
    this.roslibService
      .subscribeTopic<InputSpeedMessage>(
        [this],
        '/Status/FastSpeed',
        'std_msgs/Bool'
      )
      .subscribe((message: InputSpeedMessage) => {
        if (this.uid === message.id) {
          this.speedMode =
            message.data === true ? RobotSpeed.FAST : RobotSpeed.SLOW;
        }
      });
  }

  /**
   * Etat du système
   */
  private systemState(): void {
    this.roslibService
      .subscribeTopic<InputSystemStateMessage>(
        [this],
        '/Webui/SystemState',
        'top_level_manager_msgs/robot_infos'
      )
      .subscribe((message: InputSystemStateMessage) => {
        if (this.uid === message.id) {
          const currentStrategyAdapter: StrategyAdapter | null =
            this.currentStrategyAdapter$.getValue();

          // Positionne la stratégie en cours
          if (
            (currentStrategyAdapter === null && message.strategy_id !== -1) ||
            (currentStrategyAdapter !== null &&
              message.strategy_id !== -1 &&
              currentStrategyAdapter.strategy.id !== message.strategy_id)
          ) {
            if (
              this.robot.activeMapConfiguration &&
              this.robot.activeMapConfiguration.strategies
            ) {
              const currentStrategyAdapter: StrategyAdapter | undefined =
                this.robot.activeMapConfiguration.strategies
                  .map((strategy: Strategy) => new StrategyAdapter(strategy))
                  .find(
                    (strategyAdapter: StrategyAdapter) =>
                      strategyAdapter.strategy.id === message.strategy_id
                  );

              if (
                currentStrategyAdapter &&
                currentStrategyAdapter.strategy.patrol
              ) {
                this.patrolState = MultiState.SUCCESS;
                this.currentStrategyAdapter$.next(currentStrategyAdapter);
                this.currentStrategyType = StrategyName.STRATEGIE_WEB;
                this.alerts = [];
                this.alertState = RobotAlert.NO_ALERT;
                this.errorState = RobotError.NO_ERROR;
                this.log(
                  MultiState.INFO,
                  'strategy.type.' + StrategyName.STRATEGIE_WEB
                );
              }
            }
          } else if (
            message.strategy_id === -1 &&
            this.currentStrategyType !== this.getStrategyName(message)
          ) {
            this.patrolState = MultiState.ERROR;
            this.currentStrategyType = this.getStrategyName(message);
            this.currentStrategyAdapter$.next(null);
            this.currentGoal$.next(null);
            this.currentGoal = undefined;
            this.alerts = [];
            this.alertState = RobotAlert.NO_ALERT;
            this.errorState = RobotError.NO_ERROR;
            this.log(
              MultiState.INFO,
              'strategy.type.' + this.currentStrategyType
            );
          }

          if (
            currentStrategyAdapter !== null &&
            currentStrategyAdapter.strategy.patrol
          ) {
            const currentGoal: PatrolCheckpoint | undefined =
              currentStrategyAdapter.strategy.patrol.checkpoints.find(
                (patrolCheckpoint: PatrolCheckpoint) =>
                  patrolCheckpoint.checkpoint?.id === message.current_goal
              );

            if (
              this.currentGoal === undefined ||
              (this.currentGoal &&
                currentGoal &&
                currentGoal.id !== this.currentGoal.id)
            ) {
              this.currentGoal = currentGoal;

              if (this.currentGoal && this.currentGoal.checkpoint) {
                this.log(MultiState.INFO, 'robot.currentGoal', {
                  name: this.currentGoal.checkpoint.name,
                });
                this.currentGoal$.next(this.currentGoal);
              }
            }
          }

          // On positionne le nombre de rotations restantes
          if (
            currentStrategyAdapter &&
            currentStrategyAdapter.strategy.rotationNumber !== undefined &&
            message.current_cycle != -1
          ) {
            this.remainingRotation =
              currentStrategyAdapter.strategy.rotationNumber -
              message.current_cycle;
          } else {
            this.remainingRotation = 0;
          }

          // On positionne la fin de patrouille 
          // (on converti les secondes en minutes pour l'affichage)
          this.endOfPatrolsec = message.timeout;
          this.endOfPatrolmin = Math.floor(this.endOfPatrolsec / 60) + 1;

          if (this.uid === message.id) {
            this.state =
              message.state === 0
                ? RobotState.WAITING
                : RobotState.CURRENT_NAVIGATION;

            if (this.getStrategyName(message) === StrategyName.EMERGENCY_STOP) {
              this.errorState = RobotError.MAJOR_ERROR;
            }

            if(this.getStrategyName(message) === StrategyName.MANUAL_CONTROL){
              if(this.endOfPatrolsec > 5){
                this.ismanualcontrol = 1;
              } else {
                this.ismanualcontrol = 2;
              }
            } else {
              this.ismanualcontrol = 0;
            }

            // TODO: à revoir
            /*if (message?.state !== this.state) {
            this.errorState = RobotError.NO_ERROR;
          }*/
          }

          // Si l'état est "en attente" éteindre les alertes
          if (
            Object.keys(RobotState)[message.state] ===
            RobotState[RobotState.WAITING]
          ) {
            this.alertState = RobotAlert.NO_ALERT;
            this.alerts = [];
          }

          // On indique si le robot est dans la niche ou non
          this.inniche = this.state === RobotState.WAITING ? true : false;
        }
      });
  }

  /**
   * Position
   */
  private position(): void {
    this.roslibService
      .subscribeTopic<InputPositionMessage>(
        [this],
        '/odometry/referenced',
        'geometry_msgs/PoseWithCovarianceStamped'
      )
      .subscribe((message: InputPositionMessage) => {
        if (this.uid === message.id) {
          this.currentPosition$.next(message.pose.pose);
        }
      });
  }

  /**
   * Indique l'erreur
   */
  private error(): void {
    this.roslibService
      .subscribeTopic<InputErrorMessage>(
        [this],
        '/system/error',
        'std_msgs/Int8'
      )
      .subscribe((message: InputErrorMessage) => {
        if (this.uid === message.id) {
          if (message.data === 0) {
            this.errorState = RobotError.NO_ERROR;
          } else if (message.data === 1) {
            this.errorState = RobotError.MAJOR_ERROR;
          } else if (message.data === 2) {
            this.errorState = RobotError.MINOR_ERROR;
          }

          // Si le nouveau message d'erreur est différent du dernier reçu.
          if(this.logs[this.logs.length - 1].text !== 'robot.error.' + this.errorState){
            if(this.errorState !== RobotError.NO_ERROR){
              this.log(MultiState.ERROR, 'robot.error.' + this.errorState);
            } else {
              this.log(MultiState.INFO, 'robot.error.' + this.errorState);
            }
          }
        }
      });
  }

  /**
   * Indique l'alerte
   */
  private alert(): void {
    this.roslibService
      .subscribeTopic<InputAlertMessage>(
        [this],
        '/system/alert',
        'std_msgs/Int8'
      )
      .subscribe((message: InputAlertMessage) => {
        if (this.uid === message.id) {
          const alert: RobotAlert = this.getAlert(message);
          if(alert !== RobotAlert.HUMAN_SUSPICION){
            this.alerts.push(alert);
          }
          this.alertState = this.getAlertState();

          // Si la nouvelle alerte est différente de la précédente
          //    Si RobotAlert.HUMAN_DETECTION et RobotAlert.HUMAN_SUSPICION ne se suivent pas (peut importe l'ordre),
          //        Si ce n'est pas RobotAlert.HUMAN_SUSPICION -> alors on log
          //          Si l'alerte actuelle n'est pas NO_ALERT et que l'alerte précedente n'est pas HUMAN_SUSPICION -> alors on log
          if(this.prevAlertState !== alert){
            if(!(((alert === RobotAlert.HUMAN_DETECTION) && (this.prevAlertState === RobotAlert.HUMAN_SUSPICION)) || ((alert === RobotAlert.HUMAN_SUSPICION) && (this.prevAlertState === RobotAlert.HUMAN_DETECTION)))){
              if(alert !== RobotAlert.HUMAN_SUSPICION){
                if(!((alert === RobotAlert.NO_ALERT) && (this.prevAlertState === RobotAlert.HUMAN_SUSPICION))){
                  this.log(MultiState.WARNING, 'robot.alertJDB.' + this.alertState);
                } 
              } 
            } 
          } 

          this.prevAlertState = this.alertState;
        }
      });
  }

  private getAlert(message: InputAlertMessage): RobotAlert {
    let robotAlert: RobotAlert = RobotAlert.NO_ALERT;

    if (message.data === 0) {
      robotAlert = RobotAlert.NO_ALERT;
    } else if (message.data === 1) {
      robotAlert = RobotAlert.HUMAN_DETECTION;
    } else if (message.data === 2) {
      robotAlert = RobotAlert.ROBOT_TAKE_OFF;
    } else if (message.data === 3) {
      robotAlert = RobotAlert.PATH_BLOCKED;
    } else if (message.data === 4) {
      robotAlert = RobotAlert.JAMMING;
    } else if (message.data === 5) {
      robotAlert = RobotAlert.FIRE;
    } else if (message.data === 6) {
      robotAlert = RobotAlert.HUMAN_SUSPICION;
    }

    this.alerts = this.alerts.filter(
      (alert: RobotAlert) => alert !== robotAlert
    );

    return robotAlert;
  }

  private getStrategyName(
    message: InputSystemStateMessage
  ): StrategyName | null {
    let strategyName: StrategyName | null = null;

    if (message.strategy_type === 0) {
      strategyName = StrategyName.VERY_LOWER_STOP;
    } else if (message.strategy_type === 1) {
      strategyName = StrategyName.EMERGENCY_STOP;
    } else if (message.strategy_type === 2) {
      strategyName = StrategyName.LOW_POWER;
    } else if (message.strategy_type === 3) {
      strategyName = StrategyName.GET_UP;
    } else if (message.strategy_type === 4) {
      strategyName = StrategyName.MANUAL_CONTROL;
    } else if (message.strategy_type === 5) {
      strategyName = StrategyName.EXPLORATION;
    } else if (message.strategy_type === 20) {
      strategyName = StrategyName.STRATEGIE_WEB;
    } else if (message.strategy_type === 98) {
      strategyName = StrategyName.RETOUR_BASE;
    } else if (message.strategy_type === 99) {
      strategyName = StrategyName.NICHE;
    } else if (message.strategy_type === 100) {
      strategyName = StrategyName.CHARGE_MONITORING;
    }

    return strategyName;
  }

  /**
   * Retourne la dernière alerte s'il y a des alertes sinon retourn NO_ALERT
   *
   * @private
   */
  private getAlertState(): RobotAlert {
    return this.alerts.length > 0
      ? this.alerts[this.alerts.length - 1]
      : RobotAlert.NO_ALERT;
  }

  /**
   * Etat de la configuration
   *
   * Si le robot a une carte active et une configuration active, et que cette configuration à au moins une stratégie la configuration est ok sinon ko
   *
   * @private
   */
  private configuration(): void {
    if (
      this.robot.activeMap &&
      this.robot.activeMapConfiguration &&
      this.robot.activeMapConfiguration.strategies &&
      this.robot.activeMapConfiguration.strategies.length > 0
    ) {
      this.configurationState = MultiState.SUCCESS;
    } else {
      this.configurationState = MultiState.ERROR;
    }
  }

  private bie(): void {
    this.roslibService
      .subscribeTopic<InputBieMessage>([this], '/alarm_in', 'std_msgs/String')
      .subscribe((m: InputBieMessage) => {
        if (this.uid === m.id) {
          clearTimeout(this.bieTimer);
          this.bieState = MultiState.SUCCESS;

          this.bieTimer = setTimeout(() => {
            this.bieState = MultiState.ERROR;
          }, 10000);

          const data: string[] = (m as any).data.split(':');
          const x = Number(data[0]);
          const y: boolean = Number(data[1]) === 0 ? false : true;

          if (this.id) {
            const find: InputBie | undefined = this.bieValues.find(
              (inputBie: InputBie) => inputBie.x === x
            );

            if (find) {
              find.id = this.id;
              find.y = y;
            }
          }
        }
      });
  }

  private log(
    state: MultiState,
    text: string,
    parameters: { [key: string]: any } = {}
  ): void {
    this.logs.push({
      date: new Date(),
      state: state,
      text: text,
      parameters: parameters,
    });
  }

  /**
   * Indique le statut de la carte en cours de création
   *
   * @private
   */
  public mapCreationStatus(): Observable<InputMapCreationStatusMessage> {
    return this.roslibService
      .subscribeTopic<InputMapCreationStatusMessage>(
        [this],
        '/Status/MapRegistered',
        'top_level_manager_msgs/map_status'
      )
      .pipe(filter((m: InputMapCreationStatusMessage) => this.uid === m.id));
  }

  /**
   * Allume/Eteint la lumière du robot
   *
   * @param state ON pour allumer, OFF pour éteindre
   */
  public lightCommand(state: RobotLight): void {
    this.roslibService.publishTopic<OutputLightMessage>(
      [this],
      '/core2/light_forced',
      {
        data: state === RobotLight.ON ? true : false,
      }
    );
  }

  /**
   * Allume/Eteint la lumière du robot
   *
   * @param mode SLOW pour lent, FAST pour rapide
   */
  public speedCommand(mode: RobotSpeed): void {
    this.roslibService.publishTopic<OutputSpeedMessage>(
      [this],
      '/core2/fastSpeed',
      {
        data: mode === RobotSpeed.FAST ? true : false,
      }
    );
  }

  /**
   * Envoi la commande d'arrêt d'urgence
   *
   * @param strategy la stratégie à envoyer
   * @param forcedDeparture la valeur de l'arrêt (true pour arrêt d'urgence demandé, et false pour arrêt d'urgence désactivé)
   */
  public forcedDepartureCommand(
    strategy: Strategy,
    forcedDeparture: boolean
  ): void {
    if (strategy.id) {
      this.roslibService.publishTopic<OutputForcedDepartureMessage>(
        [this],
        '/Webui/Request',
        {
          strategy_id: strategy.id,
          strategy_type: StrategyName.STRATEGIE_WEB,
          request: forcedDeparture
            ? StrategyRequest.DEMARRAGE_FORCE
            : StrategyRequest.ARRET_FORCE,
        }
      );
    }
  }

  /**
   * Envoi la commande d'inhibition d'une stratégie
   *
   * @param strategy la stratégie à envoyer
   */
  public inhibitStrategyCommand(strategy: Strategy): void {
    if (strategy.id) {
      this.roslibService.publishTopic<OutputInhibitStrategyMessage>(
        [this],
        '/Webui/Request',
        {
          strategy_id: strategy.id,
          strategy_type: StrategyName.STRATEGIE_WEB,
          request: 0,
        }
      );
    }
  }

  /**
   * Envoi la commande pour le mouvement de la caméro
   *
   * @param robot le robot sur le quel envoyer la commande
   * @param motion la valeur du mouvement
   */
  public forcedMotionCommand(motion: Motion): void {
    this.roslibService.publishTopic<OutputForcedMotionMessage>(
      [this],
      '/Webui/Motion',
      {
        data: motion,
      }
    );
  }

  /**
   * Envoi la commande de configuration des points de patrouille
   *
   * @param m la carte sur laquelle envoyer la commande
   */
  public setCheckpointsCommand(
    mapConfiguration: MapConfiguration,
    mapId: number,
    confId: number
  ): void {
    if (mapConfiguration.niche && mapConfiguration.checkpoints) {
      // Ajout du point de contrôle de la niche
      const nicheCheckpointDTO: CheckpointDTO = new CheckpointDTO();
      const nicheDistance = 1.0;
      const nicheTheta =
        Utils.quaternionToGlobalTheta(mapConfiguration.niche.orientation) *
        (Math.PI / 180);
      nicheCheckpointDTO.id = 0;
      nicheCheckpointDTO.frame_id = 'map';
      nicheCheckpointDTO.x =
        mapConfiguration.niche.position.x -
        nicheDistance * Math.cos(nicheTheta);
      nicheCheckpointDTO.y =
        mapConfiguration.niche.position.y -
        nicheDistance * Math.sin(nicheTheta);
      nicheCheckpointDTO.theta = nicheTheta;

      const checkpointDtos: CheckpointDTO[] = [nicheCheckpointDTO];

      mapConfiguration.checkpoints.forEach((checkpoint: Checkpoint) => {
        const checkpointTransformer: CheckpointTransformer =
          new CheckpointTransformer();
        checkpointDtos.push(
          checkpointTransformer.to(checkpoint) as CheckpointDTO
        );
      });

      this.roslibService.publishTopic<OutputSetCheckpointMessage>(
        [this],
        '/Webui/Checkpoints',
        {
          header: {
            frame_id: '',
          },
          map_id: mapId,
          conf_id: confId,
          checkpoint_list: checkpointDtos,
        }
      );
    }
  }

  /**
   * Envoi la commande de configuration de stratégie
   *
   */
  public setStrategyCommand(
    strategies: Strategy[],
    mapId: number,
    confId: number
  ): void {
    let strategyDtos: StrategyDTO[] = [];
    const scheduledStrategyDtos: StrategyDTO[] = [];
    const triggeredStrategyDtos: StrategyDTO[] = [];

    strategies.sort((s1: Strategy, s2: Strategy) =>
      s1.position && s2.position && s1.position > s2.position ? 1 : -1
    );

    strategies.forEach((strategy: Strategy) => {
      const strategyTransformer: StrategyTransformer =
        new StrategyTransformer();

      if (strategy.mode === StrategyMode.TRIGGERED) {
        triggeredStrategyDtos.push(strategyTransformer.to(strategy));
      } else if (strategy.mode === StrategyMode.SCHEDULED) {
        scheduledStrategyDtos.push(strategyTransformer.to(strategy));
      }
    });

    strategyDtos = [...triggeredStrategyDtos, ...scheduledStrategyDtos];

    this.roslibService.publishTopic<OutputSetStrategyMessage>(
      [this],
      '/Webui/Strategies',
      {
        header: {
          frame_id: '',
        },
        map_id: mapId,
        conf_id: confId,
        strategies_list: strategyDtos,
      }
    );
  }

  /**
   * Envoi la commande d'exploration de carte
   *
   * @param map la carte en cours d'exploration
   */
  public explorationMapCommand(map: Map): void {
    if (map.id) {
      this.roslibService.publishTopic<OutputExplorationMapMessage>(
        [this],
        '/Webui/Request',
        {
          strategy_id: map.id,
          strategy_type: StrategyName.EXPLORATION,
          request: StrategyRequest.DEMARRAGE_FORCE,
        }
      );
    }
  }

  /**
   * Envoi la commande d'annulation de l'exploration de la carte
   */
  public abortExplorationCommand(): void {
    this.roslibService.publishTopic<OutputAbortExplorationMessage>(
      [this],
      '/explore/abort',
      {
        data: true,
      }
    );
  }

  /**
   * Envoi la commande de chargement de la carte
   *
   * @param id identifiant de la carte à charger
   */
  public loadMapCommand(id: number, conf_id: number): void {
    this.roslibService.publishTopic<OutputLoadMapMessage>(
      [this],
      '/Webui/MapLoading',
      {
        header: {
          frame_id: '',
        },
        map_id: id,
        conf_id: conf_id,
      }
    );
  }

  /**
   * Envoi la commande de désactivation de la carte
   *
   */
  public desactivateMapCommand(): void {
    this.roslibService.publishTopic<OutputDesactivateMapMessage>(
      [this],
      '/Webui/Request',
      {
        strategy_id: -1,
        strategy_type: StrategyName.DESACTIVATE_MAP,
        request: StrategyRequest.DEMARRAGE_FORCE,
      }
    );
  }

  /**
   * Envoi la commande de configuration du robot
   *
   * @param robot le robot sur le quel envoyer la commande
   */
  public setConfigurationRobotCommand(robot: Robot): void {
    const robotTransformer: RobotTransformer = new RobotTransformer();
    const robot_msg: RobotDTO = robotTransformer.to(robot) as RobotDTO;

    this.roslibService.publishTopic<RobotDTO>([this], '/Webui/Configuration', {
      robot_id: robot_msg.robot_id,
      robot_name: robot_msg.robot_name,
      ssid: robot_msg.ssid,
      pwd: robot_msg.pwd,
      ip: robot_msg.ip,
      mask: robot_msg.mask,
      gateway: robot_msg.gateway,
      call_alert_enable: robot_msg.call_alert_enable,
      phone_number: robot_msg.phone_number,
      email_enable: robot_msg.email_enable,
      email_address: robot_msg.email_address,
      BIE_enable: robot_msg.BIE_enable,
      BIE_output0: robot_msg.BIE_output0,
      BIE_output1: robot_msg.BIE_output1,
      BIE_output2: robot_msg.BIE_output2,
      BIE_output3: robot_msg.BIE_output3,
      onvif_enable: robot_msg.onvif_enable,
      onvif_port_rtsp: robot_msg.onvif_port_rtsp,
      onvif_port_http: robot_msg.onvif_port_http,
      onvif_username: robot_msg.onvif_username,
      onvif_password: robot_msg.onvif_password,
      patrol_anomaly_enable: robot_msg.patrol_anomaly_enable,
      jamming_enable: robot_msg.jamming_enable,
      fire_detection_enable: robot_msg.fire_detection_enable,
    });
  }

  /**
   * Envoi la commande d'arrêt d'urgence
   *
   * @param value la valeur de l'arrêt (true pour arrêt d'urgence demandé, et false pour arrêt d'urgence désactivé)
   */
  public emergencyCommand(value: boolean): void {
    this.roslibService.publishTopic<OutputEmergencyMessage>(
      [this],
      '/Webui/Request',
      {
        strategy_type: StrategyName.EMERGENCY_STOP,
        request: value
          ? StrategyRequest.DEMARRAGE_FORCE
          : StrategyRequest.ARRET_FORCE,
      }
    );
  }

  /**
   * Indique le statu de l'accessoire
   */
  private getAccessoriesStatus(): void {
    this.roslibService
      .subscribeTopic<InputAccessoriesStatusMessage>(
        [this],
        '/Status/Accessories',
        'std_msgs/Int8'
      )
      .subscribe((message: InputAccessoriesStatusMessage) => {
        if (this.uid === message.id) {
          this.accessoriesStatus = message.data;
          if(this.accessoriesStatus === 0){
            this.isarming = false; 
            this.isfire   = false;
          } else if(this.accessoriesStatus === 1){
            this.isarming = true; 
            this.isfire   = false; 
          } else if(this.accessoriesStatus === 2){
            this.isarming = true; 
            this.isfire   = true;
          }
        }
      });
  } 

  /**
   * Envoi la commande d'armement de l'accessoire
   *
   * @param value la valeur de l'armement (true pour armer, et false pour désarmer)
   */
  public armingCommand(value: boolean): void {
    this.roslibService.publishTopic<OutputArmingMessage>(
      [this],
      '/Webui/Accessories/Arming',
      {
        data: value,
      }
    );
  }

  /**
   * Envoi la commande de mise à feu de l'accessoire
   *
   * @param value la valeur de la mise à feu (true pour feu, et false pour pas feu)
   */
    public fireCommand(value: boolean): void {
      this.roslibService.publishTopic<OutputFireMessage>(
        [this],
        '/Webui/Accessories/Fire',
        {
          data: value,
        }
      );
    }

}
