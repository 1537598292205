import { EntityTransformer } from './entity-transformer';
import { Robot } from '../../../../api/models/robot';
import { RobotDTO } from '../dto/robot-dto';
import { RobotAlarm } from '../../../enums/robot-alarm.enum';
import { RobotAnomalie } from '../../../enums/robot-anomalie.enum';

export class RobotTransformer implements EntityTransformer<Robot, RobotDTO> {
  to(from: Robot): RobotDTO[] | RobotDTO {
    const robotDTO: RobotDTO = new RobotDTO();

    robotDTO.robot_name = from.name;
    robotDTO.robot_id = from.id;
    robotDTO.email_enable = from.emailAlert;
    robotDTO.email_address = from.email;
    robotDTO.call_alert_enable = from.phoneAlert;
    robotDTO.phone_number = from.phone;
    robotDTO.patrol_anomaly_enable = from.patrolAnomaliesAlert;
    robotDTO.jamming_enable = from.rfJammingAlert;
    robotDTO.fire_detection_enable = from.fireAlarm;
    robotDTO.BIE_enable = from.externalInterfaces;
    robotDTO.BIE_output0 = this.outputs(from.outputs[0]);
    robotDTO.BIE_output1 = this.outputs(from.outputs[1]);
    robotDTO.BIE_output2 = this.outputs(from.outputs[2]);
    robotDTO.BIE_output3 = this.outputs(from.outputs[3]);
    robotDTO.onvif_enable = from.onvif;
    robotDTO.onvif_port_http = from.onvifHttpPort ? from.onvifHttpPort : 0;
    robotDTO.onvif_port_rtsp = from.onvifRtspPort ? from.onvifRtspPort : 0;
    robotDTO.onvif_username = from.onvifUsername ? from.onvifUsername : '';
    robotDTO.onvif_password = from.onvifPassword ? from.onvifPassword : '';
    robotDTO.ssid = from.wifiSsid ? from.wifiSsid : '-1';
    robotDTO.pwd = from.wifiPassword ? from.wifiPassword : '-1';
    robotDTO.ip = from.wifiIp ? from.wifiIp : '-1';
    robotDTO.mask = from.wifiMask ? from.wifiMask : '-1';
    robotDTO.gateway = from.wifiRouterIp ? from.wifiRouterIp : '-1';

    return robotDTO;
  }

  private outputs(outputs: string[]): number {
    if (outputs) {
      let bit: number[] = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
      ];

      const humanDetection = outputs.find(
        (alarm: string) => alarm === RobotAlarm.HUMAN_DETECTION
      );
      const robotLifted = outputs.find(
        (alarm: string) => alarm === RobotAlarm.ROBOT_TAKE_OFF
      );
      const patrolAnomalie = outputs.find(
        (alarm: string) => alarm === RobotAlarm.PATROL_ANOMALIE
      );
      const wifiJamming = outputs.find(
        (alarm: string) => alarm === RobotAlarm.JAMMING
      );
      const fireDetection = outputs.find(
        (alarm: string) => alarm === RobotAlarm.FIRE_DETECTION
      );

      if (humanDetection) {
        bit[0] = 1;
      }

      if (robotLifted) {
        bit[1] = 1;
      }

      if (patrolAnomalie) {
        bit[2] = 1;
      }

      if (wifiJamming) {
        bit[3] = 1;
      }

      if (fireDetection) {
        bit[4] = 1;
      }

      const unableToReturnToBase = outputs.find(
        (anomalie: string) =>
          anomalie === RobotAnomalie.UNABLE_TO_RETURN_TO_BASE
      );
      const weakBatteries = outputs.find(
        (anomalie: string) => anomalie === RobotAnomalie.WEAK_BATTERIES
      );
      const robotInitializationFault = outputs.find(
        (anomalie: string) =>
          anomalie === RobotAnomalie.ROBOT_INITIALIZATION_FAULT
      );

      if (unableToReturnToBase) {
        bit[5] = 1;
      }

      if (weakBatteries) {
        bit[6] = 1;
      }

      if (robotInitializationFault) {
        bit[7] = 1;
      }

      bit = bit.reverse();

      const bits: string = bit.toString().replace(new RegExp(',', 'g'), '');

      const result: number = parseInt((bits + '').replace(/[^01]/gi, ''), 2);

      return result;
    }

    return 0;
  }
}
