import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { BackStatus } from '../../../core/enums/back-status.enum';
import { PatrolCheckpointAdapter } from '../../../core/models/entities/patrol-checkpoint-adapter';
import { PatrolCheckpoint } from '../../../api/models/patrol-checkpoint';
import { MapPoint, MapPointService } from '@lelab31/ngx-rodotic';
import { filter, ReplaySubject, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PatrolAdapter } from '../../../core/models/entities/patrol-adapter';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CheckpointAdapter } from '../../../core/models/entities/checkpoint-adapater';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MapSettingsService } from '../../../core/services/map-settings.service';
import { Checkpoint } from '../../../api/models/checkpoint';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../../../core/services/notification.service';
import { Utils } from '../../../core/utils/utils';

@Component({
  selector: 'webclient-patrol-checkpoint-list',
  templateUrl: './patrol-checkpoint-list.component.html',
  styleUrls: ['./patrol-checkpoint-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-patrol-checkpoint-list',
  },
})
export class PatrolCheckpointListComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * Ce Subject permet de fermer les subscribe pour eviter les fuites lors de la fermeture de la page
   */
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Liste des points de patrouille
   */
  @Input() public patrolAdapter: PatrolAdapter | undefined;

  /**
   * Indique s'il faut afficher le titre
   */
  @Input() public displayHeader = true;

  /**
   * Titre
   */
  @Input() title: string;

  /**
   * Permet de réorganiser les stratégies
   */
  @Input() public canReorder = false;

  /**
   * Permet d'accéder aux actions
   */
  @Input() public canAction = false;

  /**
   * Mode editable ou non
   */
  @Input() public editable = false;

  /**
   * Nom des colonnes présent lors de l'édition
   */
  public editableColumnsName = ['indicator'];

  /**
   * Nom des colonnes pour la réorganisation des stratégies
   */
  public reorderColumnsName = ['reorder'];

  /**
   * Nom des colonnes pour les actions
   */
  public actionColumnsName = ['actions'];

  /**
   * Colonnes affichées pour les points de patrouille
   */
  public displayedColumns: string[] = [
    'number',
    'name',
    'duration',
    'oriented',
    'orientation',
    'sentinel',
    'fast',
  ];

  /**
   * Détecte le changement d'un champ d'un point de contrôle de la patrouille
   */
  private propertyInput: Subject<PatrolCheckpointAdapter> = new Subject();

  /**
   * Statut en BDD
   */
  public BACK_STATUS = BackStatus;

  /**
   * Bloc à afficher quand une patrouille n'a pas de points de contrôle
   */
  public emptyCheckpoints = new MatTableDataSource([{ empty: 'row' }]);

  public dataSource: MatTableDataSource<PatrolCheckpointAdapter> =
    new MatTableDataSource<PatrolCheckpointAdapter>([]);

  public patrolAdapterId = '';

  /**
   * Points de patrouilles
   *
   * @private
   */
  private mapPoints: MapPoint[] = [];

  constructor(
    private mapPointService: MapPointService,
    private mapSettingsService: MapSettingsService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {
    this.title = this.translateService.instant('patrol.patrols');
  }

  ngOnInit(): void {
    this.addColumns();

    this.listenChangeProperties();
    this.listenChangeCheckpointName();
    this.listenSelectedPatrolAdapter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const patrolAdapterChange = changes['patrolAdapter'];
    
    if (patrolAdapterChange && patrolAdapterChange.currentValue) {
      // Si patrolAdapter est défini, on continue comme avant
      this.patrolAdapterId = patrolAdapterChange.currentValue.uid;

      // Si patrolAdapter est undefined, on vide la source de données
      const checkpointAdapters = this.patrolAdapter?.patrolCheckpointAdapters || [];
      this.dataSource = new MatTableDataSource<PatrolCheckpointAdapter>(checkpointAdapters);
    } else {
      // S'il n'y a pas de points de contrôle, on vide la source de données
      this.dataSource = new MatTableDataSource<PatrolCheckpointAdapter>([]);
    }
  }

  /**
   * Ajout des colonnes
   *
   * @private
   */
  private addColumns(): void {
    if (this.canReorder === true) {
      this.displayedColumns = [
        ...this.reorderColumnsName,
        ...this.displayedColumns,
      ];
    }

    if (this.editable === true) {
      this.displayedColumns = [
        ...this.editableColumnsName,
        ...this.displayedColumns,
      ];
    }

    if (this.canAction === true) {
      this.displayedColumns = [
        ...this.displayedColumns,
        ...this.actionColumnsName,
      ];
    }
  }

  /**
   * Ecoute les changements des propriétés du point de patrouille
   *
   * @private
   */
  private listenChangeProperties(): void {
    this.propertyInput
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(300)
      )
      .subscribe((patrolCheckpointAdapter: PatrolCheckpointAdapter) => {
        if (this.patrolAdapter) {
          this.patrolAdapter.patrolCheckpointAdapters.map(
            (pca: PatrolCheckpointAdapter) =>
              pca.patrolCheckpoint.id ===
              patrolCheckpointAdapter.patrolCheckpoint.id
                ? patrolCheckpointAdapter
                : pca
          );
          this.mapSettingsService.updatePatrols(this.patrolAdapter)
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            (patrolAdapter: PatrolAdapter | PatrolAdapter[]) => {},
            (httpErrorResponse: HttpErrorResponse) => {
              if (this.patrolAdapter) {
                this.patrolAdapter.status = BackStatus.NOT_SAVED;
              }
              const title: string =
                this.translateService.instant('entity.error');
              const message: string = this.translateService.instant(
                `${httpErrorResponse.error.detail}`
              );
              this.notificationService.displayErrorSnackBar(title, message);
            }
          );
        }
      });
  }

  /**
   * Ecoute les changements de nom d'un point de contrôle
   *
   * @private
   */
  private listenChangeCheckpointName(): void {
    this.mapSettingsService.checkpoint$
      .pipe(
        takeUntil(this.destroyed$),
        filter(
          (checkpointAdapter: CheckpointAdapter | null) => !!checkpointAdapter
        )
      )
      .subscribe((checkpointAdapter) => {
        if (this.patrolAdapter) {
          this.patrolAdapter.patrolCheckpointAdapters.forEach(
            (patrolCheckpointAdapter: PatrolCheckpointAdapter) => {
              if (patrolCheckpointAdapter.patrolCheckpoint.checkpoint) {
                if(patrolCheckpointAdapter.patrolCheckpoint.checkpoint.id === checkpointAdapter?.checkpoint.id){

                  // mise à jour du nom du point de contrôle.
                  patrolCheckpointAdapter.patrolCheckpoint.checkpoint.name = checkpointAdapter?.checkpoint.name;

                  if(patrolCheckpointAdapter?.patrolCheckpoint?.checkpoint?.pose && patrolCheckpointAdapter.patrolCheckpoint.pose && checkpointAdapter?.checkpoint.pose && patrolCheckpointAdapter?.mapPoint?.pose){

                    // on synchronise patrolCheckpointAdapter.patrolCheckpoint.pose et patrolCheckpointAdapter.mapPoint.pose avec patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose
                    if((patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.x !== checkpointAdapter?.checkpoint.pose?.position.x) || 
                        (patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.y !== checkpointAdapter?.checkpoint.pose?.position.y)){
  
                      patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.x = checkpointAdapter?.checkpoint.pose?.position.x
                      patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.y = checkpointAdapter?.checkpoint.pose?.position.y
                      
                      patrolCheckpointAdapter.mapPoint.pose.position.x = checkpointAdapter?.checkpoint.pose?.position.x
                      patrolCheckpointAdapter.mapPoint.pose.position.y = checkpointAdapter?.checkpoint.pose?.position.y

                      this.mapPointService.updatePosition(
                        patrolCheckpointAdapter.mapPoint,
                        patrolCheckpointAdapter.mapPoint.pose.position.x,
                        patrolCheckpointAdapter.mapPoint.pose.position.y
                      );

                      this.dataSource._updateChangeSubscription();
                    }
                  }
                }
              }
            }
          );
        }
      });
  }

  /**
   * Ecoute le changement de sélection d'une patrouille
   *
   * @private
   */
  private listenSelectedPatrolAdapter(): void {
    this.mapSettingsService.selectedPatrol$
      .pipe(
        takeUntil(this.destroyed$),
        filter(
          (patrolAdapter: PatrolAdapter | null) =>
            patrolAdapter !== null &&
            patrolAdapter.patrol.id === this.patrolAdapter?.patrol.id
        )
      )
      .subscribe((patrolAdapter: PatrolAdapter | null) => {
        this.createMapPoints();
      });
  }

  /**
   * Permet d'afficher les points de patrouille orienté, 
   * et de masquer ceux qui ne sont pas orienté.
   *
   * @param patrolCheckpointAdapter le point de patrouille à mettre à jour
   */
  public HideOrientedPatrolCheckpoint(patrolCheckpointAdapter: PatrolCheckpointAdapter): void {

    if(patrolCheckpointAdapter.mapPoint?.pose && patrolCheckpointAdapter.patrolCheckpoint?.checkpoint?.pose && patrolCheckpointAdapter.patrolCheckpoint.pose){
      patrolCheckpointAdapter.patrolCheckpoint.pose.position.x = patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.x
      patrolCheckpointAdapter.patrolCheckpoint.pose.position.y = patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose.position.y

      if(patrolCheckpointAdapter.patrolCheckpoint.oriented){
        patrolCheckpointAdapter.mapPoint.pose = patrolCheckpointAdapter.patrolCheckpoint.pose
        if (this.patrolAdapter) {
          if (patrolCheckpointAdapter.mapPoint) {
            this.mapPointService.removePoint(
              patrolCheckpointAdapter.mapPoint
            );
            patrolCheckpointAdapter.mapPoint.displayObject = Utils.createMapPatrolCheckpoint();

            this.mapPointService.addOrUpdatePoint(
              patrolCheckpointAdapter.mapPoint
            );
          }
        }
      } else {
        patrolCheckpointAdapter.mapPoint.pose = patrolCheckpointAdapter.patrolCheckpoint.checkpoint.pose
        if (this.patrolAdapter) {
          if (patrolCheckpointAdapter.mapPoint) {
            this.mapPointService.removePoint(
              patrolCheckpointAdapter.mapPoint
            );
            patrolCheckpointAdapter.mapPoint.displayObject = Utils.createMapPatrolCheckpointNotOriented();
            this.mapPointService.addOrUpdatePoint(
              patrolCheckpointAdapter.mapPoint
            );

          }
        }
      }
    }
  }

  /**
   * Changement d'une propriété d'un point de patrouille
   *
   * @param propriété à modifier
   * @param value valeur de la propriété à modifier
   * @param patrolCheckpointAdapter le point de patrouille à mettre à jour
   */
  public onChangeProperty(
    property: string,
    value: string | boolean,
    patrolCheckpointAdapter: PatrolCheckpointAdapter
  ) {
    patrolCheckpointAdapter.patrolCheckpoint[
      property as keyof PatrolCheckpoint
    ] = <never>value;

    this.HideOrientedPatrolCheckpoint(patrolCheckpointAdapter);

    if (property === 'orientation' && patrolCheckpointAdapter.mapPoint) {
      this.mapPointService.updateOrientation(
        patrolCheckpointAdapter.mapPoint,
        patrolCheckpointAdapter.mapPoint.pose.orientation
      );
    }

    this.propertyInput.next(patrolCheckpointAdapter);
  }

  /**
   * Suppression d'un point de patrouille
   *
   * @param patrolCheckpointAdapter le point de patrouille à supprimer
   */
  public onDeletePatrolCheckpoint(
    patrolCheckpointAdapter: PatrolCheckpointAdapter
  ): void {
    if (this.patrolAdapter && patrolCheckpointAdapter.index !== undefined) {
      this.patrolAdapter.removePatrolCheckpointAdapter(patrolCheckpointAdapter);
      this.dataSource._updateChangeSubscription();

      this.mapSettingsService.updatePatrols(this.patrolAdapter)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (patrolAdapter: PatrolAdapter | PatrolAdapter[]) => {
          if (this.patrolAdapter) {
            if (patrolCheckpointAdapter.mapPoint) {
              this.mapPointService.removePoint(
                patrolCheckpointAdapter.mapPoint
              );
            }
          }
        },
        (httpErrorResponse: HttpErrorResponse) => {
          if (this.patrolAdapter) {
            this.patrolAdapter.status = BackStatus.NOT_SAVED;
          }

          const title: string = this.translateService.instant('entity.error');
          const message: string = this.translateService.instant(
            `${httpErrorResponse.error.detail}`
          );
          this.notificationService.displayErrorSnackBar(title, message);
        }
      );
    }
  }

  /**
   * Création des points de contrôle des patrouilles sur la carte
   *
   * @param patrolAdapter la patrouille
   * @private
   */
  private createMapPoints(): void {
    if (this.patrolAdapter) {
      this.patrolAdapter.patrolCheckpointAdapters.map(
        (patrolCheckpointAdapter: PatrolCheckpointAdapter) => {
            this.HideOrientedPatrolCheckpoint(patrolCheckpointAdapter);          
        }
      );
    }
  }

  /**
   * Recalcule la position des points de patrouille
   *
   * @private
   */
  private refreshPosition(): void {
    this.patrolAdapter?.patrolCheckpointAdapters.forEach(
      (patrolCheckpointAdapter: PatrolCheckpointAdapter, index: number) => {
        patrolCheckpointAdapter.patrolCheckpoint.position = index + 1;
      }
    );

    this.dataSource._updateChangeSubscription();
  }

  /**
   * Déplacement d'un point de contrôle sur une patrouille ou changement de position d'un point de patrouille
   *
   * @param event
   */
  public onDropTable(
    event: CdkDragDrop<PatrolCheckpointAdapter[] | undefined, any>
  ) {
    // Si je déplace un point de contrôle dans la liste des points de patrouille d'une patrouille
    // Sinon si je change la position d'un point de patrouille
    if (
      event.isPointerOverContainer &&
      event.container.id !== event.previousContainer.id &&
      this.patrolAdapter
    ) {
      const checkpoint: Checkpoint = (event.item.data as CheckpointAdapter)
        .checkpoint;
      const patrolCheckpoint = new PatrolCheckpoint(checkpoint);
      patrolCheckpoint.position = event.currentIndex + 1;
      const patrolCheckpointAdapter = new PatrolCheckpointAdapter(
        patrolCheckpoint,
        this.patrolAdapter.uid
      );

      this.patrolAdapter.addPatrolCheckpointAdapter(patrolCheckpointAdapter);
      this.dataSource._updateChangeSubscription();

      this.mapSettingsService
        .updatePatrols(this.patrolAdapter)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((patrolAdapter: PatrolAdapter | PatrolAdapter[]) => {
          this.patrolAdapter = patrolAdapter as PatrolAdapter;
          
          const uid = this.patrolAdapter.patrol.id ? this.patrolAdapter.patrol.id.toString() : Utils.uniqueId();
          
          this.patrolAdapter.patrolCheckpointAdapters = this.patrolAdapter.patrol.checkpoints.map(
            (patrolCheckpoint: PatrolCheckpoint) =>
            new PatrolCheckpointAdapter(patrolCheckpoint, uid)
          );
          this.dataSource = new MatTableDataSource<PatrolCheckpointAdapter>(
            this.patrolAdapter?.patrolCheckpointAdapters
          );
          this.createMapPoints();
          this.dataSource._updateChangeSubscription();
        });
    } else if (
      event.isPointerOverContainer &&
      event.container.id === event.previousContainer.id &&
      this.patrolAdapter
    ) {
      const prevIndex: number | undefined = this.dataSource.data.findIndex(
        (patrolCheckpointAdapter: PatrolCheckpointAdapter) =>
          patrolCheckpointAdapter === event.item.data
      );

      moveItemInArray(
        this.patrolAdapter.patrolCheckpointAdapters,
        prevIndex,
        event.currentIndex
      );
      this.refreshPosition();

      this.mapSettingsService
        .updatePatrols(this.patrolAdapter)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((patrolAdapter: PatrolAdapter | PatrolAdapter[]) => {});
    }
  }

  /**
   * Lors du survol d'un point de patrouille, ce dernier est affiché sur la carte avec une impulsion
   *
   * @param patrolCheckpointAdapter le point de patrouille
   */
  public onMouseenter(patrolCheckpointAdapter: PatrolCheckpointAdapter) {
    if (patrolCheckpointAdapter.mapPoint) {
      this.mapPointService.pulsePoint(patrolCheckpointAdapter.mapPoint, true);
    }
  }

  /**
   * Lorsqu'on ne survol plus le point de patrouille, ce dernier est affiché sur la carte sans impulsion
   *
   * @param patrolCheckpointAdapter le point de patrouille
   */
  public onMouseleave(patrolCheckpointAdapter: PatrolCheckpointAdapter): void {
    if (patrolCheckpointAdapter.mapPoint) {
      this.mapPointService.pulsePoint(patrolCheckpointAdapter.mapPoint, false);
    }
  }

  /**
   * Edition de l'angle d'un point de patrouille
   *
   * @param patrolCeckpointAdapter le point de patrouille
   */
  public onChangingOrientation(
    event: { angle: number | undefined; live: boolean },
    patrolCeckpointAdapter: PatrolCheckpointAdapter
  ) {
    if (
      patrolCeckpointAdapter.uid &&
      patrolCeckpointAdapter.patrolCheckpoint.pose &&
      event.angle
    ) {
      /*const mapPoint: MapPoint = {
        id: patrolCeckpointAdapter.uid,
        group: MapPointType.PATROL_CHECKPOINT,
        pose: patrolCeckpointAdapter.patrolCheckpoint.pose
      };
      this.mapPointService.startEditingOrientationMapPoint(mapPoint, event.angle);*/
    }
  }

  /**
   * Edition de l'angle d'un point de patrouille
   *
   * @param event indique si l'on édite le point ou non
   * @param patrolCeckpointAdapter le point de patrouille
   */
  public onEditingOrientation(
    event: boolean,
    patrolCeckpointAdapter: PatrolCheckpointAdapter
  ) {
    /*if (!event && patrolCeckpointAdapter.uid && patrolCeckpointAdapter.patrolCheckpoint.pose) {
      this.mapPointService.stopEditingOrientationMapPoint();
      this.propertyInput.next(patrolCeckpointAdapter);
    }*/
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
