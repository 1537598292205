import { Component, ViewEncapsulation } from '@angular/core';
import { TriggerAdapter } from '../../../../core/models/entities/trigger-adapter';
import { TriggerService } from '../../../../core/services/entities/trigger.service';
import { Trigger } from '../../../../api/models/trigger';
import { IListText } from '../../../../components/admin/models/i-list-text';
import { EntityListComponent } from '../../../../components/admin/entity-list/entity-list.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IEntityAdapter } from '../../../../components/admin/models/i-entity-adapter';
import { SessionService } from '../../../../core/services/session.service';

@Component({
  selector: 'webclient-trigger-list',
  templateUrl: './trigger-list.component.html',
  styleUrls: ['./trigger-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TriggerListComponent extends EntityListComponent {
  /**
   * Liste des utilisateurs
   */
  public triggerAdapters: TriggerAdapter[] = [];

  /**
   * Colonnes affichées
   */
  public override displayedColumns: string[] = [
    'name',
    'hasActivatedState',
    'hasMonostableState',
  ];

  /**
   * Textes
   */
  public override text: IListText = {
    removeTitle: 'trigger.removeTitle',
    removeMessage: 'trigger.removeMessage',
  };

  constructor(
    protected override sessionService: SessionService,
    private triggerService: TriggerService,
    protected override dialog: MatDialog,
    protected override translateService: TranslateService,
    protected override snackBar: MatSnackBar
  ) {
    super(sessionService, dialog, translateService, snackBar);

    this.triggerService.getAll().subscribe((trigger: Trigger[]) => {
      this.triggerAdapters = trigger.map(
        (trigger: Trigger) => new TriggerAdapter(trigger)
      );
    });
  }

  /**
   * Suppression du déclencheur
   *
   * @param entityAdapter le déclencheur à supprimer
   */
  public onRemove(entityAdapter: IEntityAdapter) {
    const triggerAdapter: TriggerAdapter = entityAdapter as TriggerAdapter;

    if (triggerAdapter.id !== undefined) {
      this.triggerService.delete(triggerAdapter.id).subscribe(
        () => {
          const index: number = this.triggerAdapters.findIndex(
            (triggerAdapter: TriggerAdapter) =>
              triggerAdapter.id === entityAdapter.id
          );
          const triggerAdapters: TriggerAdapter[] = [...this.triggerAdapters];
          triggerAdapters.splice(index, 1);

          this.triggerAdapters = triggerAdapters;

          this.displaySuccessSnackBar(
            triggerAdapter.name,
            'trigger.removeSuccessMessage'
          );
        },
        (error) => {
          this.displayErrorSnackBar(
            triggerAdapter.name,
            'trigger.removeErrorMessage'
          );
        }
      );
    }
  }
}
