export class Trigger {
  /**
   * Identifiant
   */
  public id: number | undefined;

  /**
   * Nom
   */
  public name: string | undefined;

  /**
   * Valeur
   */
  public value: string | undefined;

  /**
   * Présence d'un état d'activation
   */
  public hasActivatedState: boolean | undefined;

  /**
   * Présence d'un état monostable
   */
  public hasMonostableState: boolean | undefined;
}
