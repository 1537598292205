import { Component, ViewEncapsulation } from '@angular/core';
import { RobotService } from '../../core/services/entities/robot.service';
import { Robot } from '../../api/models/robot';
import { RobotAdapter } from '../../core/models/entities/robot-adapter';
import { SharedService } from '../../core/services/shared.service';
import { RoslibService } from '@lelab31/ngx-rodotic';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'webclient-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'webclient-dashboard',
  },
})
export class DashboardComponent {

  /**
   * Liste des robots
   */
  public robotAdapters: RobotAdapter[] = [];


  constructor(
    private robotService: RobotService,
    private rosLibService: RoslibService,
    private sharedService: SharedService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.robotService
      .getAll()
      .pipe()
      .subscribe((robots: Robot[]) => {
        this.robotAdapters = robots.map(
          (robot: Robot) => new RobotAdapter(robot, this.rosLibService, 'small')
        );
        this.sharedService.bindRobot(
          this.robotAdapters.map(
            (robotAdapter: RobotAdapter) => robotAdapter.robot
          )
        );
        // la fonction de tri est appellée
        this.sortRobots();
      });
  }

  /*
  * la fonction qui évite la superposition des tuiles mais s'adaptent en fonction de la taille de l'écran
  */
  getFlexValue(): string {
    if (this.breakpointObserver.isMatched('(max-width: 850px)')) {
      return '100%';
    } else if (this.breakpointObserver.isMatched('(min-width: 1280px)')) {
      // je garde l'affichage d'origine en mode "lg"
      return '25%';
    } else {
      return '50%';
    }
  }

  /*
   * la fonction qui trie les robots par ordre de nom croissant (insensible aux majuscules et minuscules)
   * le return sert à repositionner l'élement 'a'
   */
  sortRobots() {
    this.robotAdapters = this.robotAdapters.sort((a, b) => {
      /*
       * Trie par nom croissant:
       *  -Si le resultat est négatif, a est placé avant b
       *  -Si le resultat est positif, b est placé avant 'a'
       *  -Si le resultat est 0, pas de changement 
      */
      return a.robot.name.localeCompare(b.robot.name, undefined, {sensitivity: 'base'})      
    });
  }

}
