import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EntityService } from './entity.service';
import { Robot } from '../../../api/models/robot';

@Injectable({
  providedIn: 'root',
})
export class RobotService extends EntityService<Robot> {
  public resourceUrl = environment.apiBaseUrl + 'robots';
}
