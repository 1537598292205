import { Component, ViewEncapsulation } from '@angular/core';
import { EntityFormComponent } from '../../../../components/admin/entity-form/entity-form.component';
import { ActivatedRoute } from '@angular/router';
import { TriggerService } from '../../../../core/services/entities/trigger.service';
import { Trigger } from '../../../../api/models/trigger';
import { FormBuilder, Validators } from '@angular/forms';
import { IEntity } from '../../../../components/admin/models/i-entity';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { NotificationService } from '../../../../core/services/notification.service';
import { SessionService } from '../../../../core/services/session.service';

@Component({
  selector: 'webclient-trigger-form',
  templateUrl: './trigger-form.component.html',
  styleUrls: ['./trigger-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TriggerFormComponent extends EntityFormComponent {
  /**
   * Trigger
   */
  public trigger: Trigger | undefined;

  /**
   * Messages d'erreur
   */
  public triggerValidationMessages = {
    name: [
      { type: 'required', message: 'trigger.validationMessages.name.required' },
    ],
    value: [
      {
        type: 'required',
        message: 'trigger.validationMessages.value.required',
      },
    ],
  };

  constructor(
    protected override route: ActivatedRoute,
    protected override sessionService: SessionService,
    private triggerService: TriggerService,
    private formBuilder: FormBuilder,
    protected override translateService: TranslateService,
    protected override notificationService: NotificationService,
    protected override location: Location
  ) {
    super(
      route,
      sessionService,
      translateService,
      notificationService,
      location
    );

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      value: ['', [Validators.required]],
      hasActivatedState: [false, [Validators.required]],
      hasMonostableState: [false, [Validators.required]],
    });

    if (this.id) {
      this.triggerService.getById(this.id).subscribe((trigger: Trigger) => {
        this.trigger = trigger;
      });
    }
  }

  /**
   * Création d'un déclencheur
   *
   * @param entity le déclencheur à créer
   */
  public onCreate(entity: IEntity) {
    const trigger: Trigger = entity as Trigger;

    this.triggerService.create(trigger).subscribe(
      (trigger: Trigger) => {
        this.displaySuccessSnackBar(
          trigger.name,
          'trigger.createSuccessMessage'
        );
      },
      (error) => {
        this.displayErrorSnackBar(trigger.name, 'trigger.createErrorMessage');
      }
    );
  }

  /**
   * Edition d'un déclencheur
   *
   * @param entity le déclencheur à éditer
   */
  public onEdit(entity: IEntity) {
    const trigger: Trigger = entity as Trigger;

    this.triggerService.update(this.id, trigger).subscribe(
      (trigger: Trigger) => {
        this.displaySuccessSnackBar(trigger.name, 'trigger.editSuccessMessage');
      },
      (error) => {
        this.displayErrorSnackBar(trigger.name, 'trigger.editErrorMessage');
      }
    );
  }
}
