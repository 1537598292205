import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

export class CustomValidators {
  /**
   * Validateur de correspondance entre deux champs
   *
   * @param controlName le nom du controlleur
   * @param matchingControlName le nom du controlleur à matcher
   */
  static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      if (!formGroup.controls) {
        return;
      }

      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  /**
   * Validateur qui permet d'avoir un nombre minimal de checkbox coché
   *
   * @param minRequired le nombre de checkbox coché minimal
   */
  static minimumRequireCheckboxesToBeChecked(minRequired = 1): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control) {
        return null;
      }

      let checked = 0;

      Object.keys((control as FormArray).controls).forEach((key: string) => {
        const ctl = (control as FormArray).controls[Number(key)];

        if (ctl.value !== false && ctl.value !== null) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          minimumRequireCheckboxesToBeChecked: true,
        };
      }

      return null;
    };
  }

  /**
   * Validateur qui permet d'avoir un nombre maximal de checkbox coché
   *
   * @param maxRequired le nombre de checkbox coché maximal
   */
  static maximumRequireCheckboxesToBeChecked(maxRequired = -1): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control) {
        return null;
      }

      let checked = 0;

      Object.keys((control as FormArray).controls).forEach((key: string) => {
        const ctl = (control as FormArray).controls[Number(key)];

        if (ctl.value !== false && ctl.value !== null) {
          checked++;
        }
      });

      if (maxRequired !== -1 && checked > maxRequired) {
        return {
          maximumRequireCheckboxesToBeChecked: true,
        };
      }

      return null;
    };
  }

  /**
   * Validateur qui permet d'avoir un nombre compris entre une valeur minimale et une valeur maximale
   *
   * @param min la valeur minimale
   * @param max la valeur maximale
   */
  static range(min: number, max: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control) {
        return null;
      }

      const value: number = control.value;

      if (value !== null && (value < min || value > max)) {
        return {
          range: true,
        };
      }

      return null;
    };
  }

  /**
   * Validateur qui permet d'avoir une chaîne de caractère d'une taille compris entre une valeur minimale et une valeur maximale
   *
   * @param minLength la valeur minimale
   * @param maxLength la valeur maximale
   */
  static rangeLength(minLength: number, maxLength: number): ValidatorFn | null {
    return (control: AbstractControl) => {
      if (!control) {
        return null;
      }

      const value: string = control.value;

      if (
        value !== null &&
        (value.length < minLength || value.length > maxLength)
      ) {
        return {
          rangelength: true,
        };
      }

      return null;
    };
  }
}
