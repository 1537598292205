<div fxLayout="row" fxLayout.xs="column">
  <webclient-card-title
    title="strategy.strategies"
    icon="model_training"
  ></webclient-card-title>

  <div fxFlex></div>

  <div fxLayoutAlign="center center">
    <!-- Bouton de création d'une patrouille -->
    <button
      mat-icon-button
      color="accent"
      title="{{ 'strategy.add' | translate }}"
      (click)="onAddStrategy()"
      *ngIf="editable"
    >
      <mat-icon [color]="'accent'" aria-label="add">add</mat-icon>
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<mat-table
  [dataSource]="dataSource"
  cdkDropList
  (cdkDropListDropped)="onDropTable($event)"
  [cdkDropListData]="strategyAdapters"
  [cdkDropListEnterPredicate]="canDrop"
>
  <!-- Colonne indicateur -->
  <ng-container matColumnDef="indicator" *ngIf="editable">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell
      *matCellDef="let strategyAdapter"
      [ngClass]="{
        saved: strategyAdapter.status === BACK_STATUS.SAVED,
        pending: strategyAdapter.status === BACK_STATUS.PENDING,
        'not-saved': strategyAdapter.status === BACK_STATUS.NOT_SAVED
      }"
    ></mat-cell>
  </ng-container>

  <!-- Colonne pour le drag and drop -->
  <ng-container matColumnDef="reorder" *ngIf="canReorder">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter">
      <mat-icon class="drag-and-drop" cdkDragHandle>reorder</mat-icon>
    </mat-cell>
  </ng-container>

  <!-- Colonne numéro -->
  <ng-container matColumnDef="number">
    <mat-header-cell *matHeaderCellDef>{{
      'strategy.number' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter">
      {{ strategyAdapter.strategy.position }}
    </mat-cell>
  </ng-container>

  <!-- Colonne nom -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>{{
      'strategy.name' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter">
      <mat-form-field *ngIf="editable">
        <input
          matInput
          placeholder="{{ 'strategy.namePlaceholder' | translate }}"
          [ngModel]="strategyAdapter.strategy.name!"
          (ngModelChange)="onChangeProperty('name', $event, strategyAdapter)"
        />
      </mat-form-field>
      <span *ngIf="!editable"> {{ strategyAdapter.strategy.name }} </span>
    </mat-cell>
  </ng-container>

  <!-- Colonne mode aléatoire -->
  <ng-container matColumnDef="randomMode">
    <mat-header-cell *matHeaderCellDef>{{
      'strategy.randomMode' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter">
      <mat-slide-toggle
        [(ngModel)]="strategyAdapter.strategy.randomMode"
        (ngModelChange)="
          onChangeProperty('randomMode', $event, strategyAdapter)
        "
        [disabled]="!editable"
      ></mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <!-- Colonne nombre de rotation -->
  <ng-container matColumnDef="rotationNumber">
    <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center">{{
      'strategy.rotationNumber' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter" fxLayoutAlign="center center">
      <webclient-number-input
        *ngIf="editable"
        [(ngModel)]="strategyAdapter.strategy.rotationNumber"
        (ngModelChange)="
          onChangeProperty('rotationNumber', $event, strategyAdapter)
        "
        [canEdit]="editable"
        [digitsInfo]="'1.0-0'"
      ></webclient-number-input>
      <span *ngIf="!editable">
        {{ strategyAdapter.strategy.rotationNumber }}
      </span>
    </mat-cell>
  </ng-container>

  <!-- Colonne duréee de patrouille -->
  <ng-container matColumnDef="patrolDuration">
    <mat-header-cell *matHeaderCellDef>{{
      'strategy.patrolDuration' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter">
      <webclient-number-input
        *ngIf="editable"
        [(ngModel)]="strategyAdapter.strategy.patrolDuration"
        (ngModelChange)="
          onChangeProperty('patrolDuration', $event, strategyAdapter)
        "
        [canEdit]="editable"
        [digitsInfo]="'1.0-0'"
      ></webclient-number-input>
      <span *ngIf="!editable">
        {{ strategyAdapter.strategy.patrolDuration }}
      </span>
    </mat-cell>
  </ng-container>

  <!-- Colonne durée de pause -->
  <ng-container matColumnDef="breakDuration">
    <mat-header-cell *matHeaderCellDef>{{
      'strategy.breakDuration' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter">
      <webclient-number-input
        *ngIf="editable"
        [(ngModel)]="strategyAdapter.strategy.breakDuration"
        (ngModelChange)="
          onChangeProperty('breakDuration', $event, strategyAdapter)
        "
        [canEdit]="editable"
        [digitsInfo]="'1.0-0'"
      ></webclient-number-input>
      <span *ngIf="!editable">
        {{ strategyAdapter.strategy.breakDuration }}
      </span>
    </mat-cell>
  </ng-container>

  <!-- Colonne choix du mode déclenché ou planifié -->
  <ng-container matColumnDef="mode">
    <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center">{{
      'strategy.mode' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter" fxLayoutAlign="center center">
      <mat-button-toggle-group
        [(ngModel)]="strategyAdapter.strategy.mode"
        (ngModelChange)="onChangeProperty('mode', $event, strategyAdapter)"
      >
        <mat-button-toggle
          [value]="STRATEGY_MODE.TRIGGERED"
          [disabled]="!editable"
        >
          <mat-icon>bolt</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [value]="STRATEGY_MODE.SCHEDULED"
          [style.backgroundColor]="
            strategyAdapter.strategy.mode === STRATEGY_MODE.SCHEDULED
              ? strategyAdapter.strategy.color
              : 'transparent'
          "
          [disabled]="!editable"
        >
          <mat-icon>schedule</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-cell>
  </ng-container>

  <!-- Colonne déclencheurs -->
  <ng-container matColumnDef="trigger">
    <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center">{{
      'strategy.trigger' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter" fxLayoutAlign="center center">
      <mat-select
        [(ngModel)]="strategyAdapter.strategy?.triggerItems[0]?.launcher.id"
        *ngIf="
          strategyAdapter.strategy?.mode === STRATEGY_MODE.TRIGGERED && editable
        "
        (ngModelChange)="onChangeProperty('trigger', $event, strategyAdapter)"
        [matTooltip]="strategyAdapter.strategy?.triggerItems[0]?.launcher.name"
      >
        <mat-option
          [value]="triggerAdapter.trigger.id"
          *ngFor="let triggerAdapter of triggerAdapters"
          >{{ triggerAdapter.trigger.name }}</mat-option
        >
      </mat-select>
      <span *ngIf="!editable">
        {{ strategyAdapter.strategy?.triggerItems[0]?.launcher.name }}
      </span>
    </mat-cell>
  </ng-container>

  <!-- Colonne état d'activation -->
  <ng-container matColumnDef="activationState">
    <mat-header-cell *matHeaderCellDef>{{
      'strategy.activationState' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter">
      <mat-slide-toggle
        *ngIf="strategyAdapter.strategy.triggerItems.length !== 0"
        [(ngModel)]="strategyAdapter.strategy.triggerItems[0].activationState"
        [disabled]="
          !editable ||
          !strategyAdapter.strategy.triggerItems[0]?.launcher.hasActivatedState
        "
        (ngModelChange)="
          onChangeProperty('activationState', $event, strategyAdapter)
        "
      ></mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <!-- Colonne état monostable -->
  <ng-container matColumnDef="monostableState">
    <mat-header-cell *matHeaderCellDef>{{
      'strategy.monostableState' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter">
      <mat-slide-toggle
        *ngIf="strategyAdapter.strategy.triggerItems.length !== 0"
        [(ngModel)]="strategyAdapter.strategy.triggerItems[0].monostableState"
        [disabled]="
          !editable ||
          !strategyAdapter.strategy.triggerItems[0]?.launcher.hasMonostableState
        "
        (ngModelChange)="
          onChangeProperty('monostableState', $event, strategyAdapter)
        "
      ></mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <!-- Colonne actions -->
  <ng-container matColumnDef="actions" *ngIf="canAction">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter; let index = dataIndex">
      <button
        mat-icon-button
        color="accent"
        (click)="onDeleteItem(strategyAdapter)"
      >
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <!-- Colonne commande -->
  <ng-container matColumnDef="commands" *ngIf="canCommand">
    <mat-header-cell *matHeaderCellDef>{{
      'strategy.commands' | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let strategyAdapter; let index = index">
      <button
        mat-icon-button
        color="accent"
        title="{{ 'strategy.forcedDeparture' | translate }}"
        (click)="onForcedDeparture(strategyAdapter, true)"
        [disabled]="robotAdapter === undefined || !robotAdapter.connected"
      >
        <mat-icon>play_circle_filled</mat-icon>
      </button>

      <button
        mat-icon-button
        color="accent"
        title="{{ 'strategy.forcedDeparture' | translate }}"
        (click)="onForcedDeparture(strategyAdapter, false)"
        [disabled]="robotAdapter === undefined || !robotAdapter.connected"
      >
        <mat-icon>stop</mat-icon>
      </button>

      <button
        mat-icon-button
        color="accent"
        title="{{
          'strategy.inhibit'
            | translate : { name: strategyAdapter?.strategy?.name }
        }}"
        (click)="onInhibit(strategyAdapter)"
        [disabled]="robotAdapter === undefined || !robotAdapter.connected"
      >
        <mat-icon>pan_tool</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <ng-container *ngIf="editable">
    <mat-row
      *matRowDef="let strategyAdapter; columns: displayedColumns"
      cdkDrag
      [cdkDragData]="strategyAdapter"
      (mouseenter)="onMouseenter(strategyAdapter)"
      (mouseleave)="onMouseleave(strategyAdapter)"
    ></mat-row>
  </ng-container>
  <ng-container *ngIf="!editable">
    <mat-row
      *matRowDef="let strategyAdapter; columns: displayedColumns"
      (mouseenter)="onMouseenter(strategyAdapter)"
      (mouseleave)="onMouseleave(strategyAdapter)"
    ></mat-row>
  </ng-container>
</mat-table>
