<webclient-entity-list
  [entities]="triggerAdapters"
  [displayedColumns]="displayedColumns"
  [urlForm]="'/pages/admin/trigger'"
  [title]="'trigger.triggers'"
  [icon]="'bolt'"
  [text]="text"
  (onRemoveEntity)="onRemove($event)"
>
  <!-- Colonne nom -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>
      {{ 'trigger.name' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let triggerAdapter">
      {{ triggerAdapter.trigger.name }}
    </mat-cell>
  </ng-container>

  <!-- Colonne présence d'un état d'activation -->
  <ng-container matColumnDef="hasActivatedState">
    <mat-header-cell *matHeaderCellDef>
      {{ 'trigger.activationState' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let triggerAdapter">
      <mat-slide-toggle
        [checked]="triggerAdapter.trigger.hasActivatedState"
        [disabled]="true"
      ></mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <!-- Colonne présence d'un état monostable -->
  <ng-container matColumnDef="hasMonostableState">
    <mat-header-cell *matHeaderCellDef>
      {{ 'trigger.monostableState' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let triggerAdapter">
      <mat-slide-toggle
        [checked]="triggerAdapter.trigger.hasMonostableState"
        [disabled]="true"
      ></mat-slide-toggle>
    </mat-cell>
  </ng-container>
</webclient-entity-list>
