import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MapService } from '../../core/services/entities/map.service';
import { ActivatedRoute } from '@angular/router';
import { Strategy } from '../../api/models/strategy';
import { MapAdapter } from '../../core/models/entities/map-adapter';
import { Patrol } from '../../api/models/patrol';
import { StrategyAdapter } from '../../core/models/entities/strategy-adapter';
import { Map } from '../../api/models/map';
import { MapConfiguration } from '../../api/models/map-configuration';
import { MapConfigurationAdapter } from '../../core/models/entities/map-configuration-adapter';
import { MapSettingsService } from '../../core/services/map-settings.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MapPoint, MapPointService, RoslibService } from '@lelab31/ngx-rodotic';
import { filter, forkJoin } from 'rxjs';
import { MapPointType } from '../../core/enums/map-point-type.enum';
import { MapConfigurationService } from '../../core/services/entities/map-configuration.service';
import { Robot } from '../../api/models/robot';
import { RobotAdapter } from '../../core/models/entities/robot-adapter';
import { RobotService } from '../../core/services/entities/robot.service';
import { PatrolAdapter } from '../../core/models/entities/patrol-adapter';
import { CheckpointAdapter } from '../../core/models/entities/checkpoint-adapater';
import { Checkpoint } from '../../api/models/checkpoint';
import { Utils } from '../../core/utils/utils';

@Component({
  selector: 'webclient-map-settings',
  templateUrl: './map-settings.component.html',
  styleUrls: ['./map-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'webclient-map-settings',
  },
})
export class MapSettingsComponent implements OnInit {
  /**
   * La carte
   */
  public mapAdapter: MapAdapter | undefined;

  /**
   * Robot
   */
  public robotAdapter: RobotAdapter | undefined;

  /**
   * Configuration courante
   */
  public currentMapConfigurationAdapter: MapConfigurationAdapter | undefined;

  /**
   * Liste des configurations
   */
  public mapConfigurationAdapters: MapConfigurationAdapter[] = [];

  /**
   * Liste des points de contrôle
   */
  public checkpointAdapters: CheckpointAdapter[] = [];

  /**
   * Liste des patrouilles
   */
  public patrolAdapters: PatrolAdapter[] = [];

  /**
   * Liste des stratégies
   */
  public strategyAdapters: StrategyAdapter[] = [];

  /**
   * Indique si les patrouilles peuvent être développées ou non
   */
  public patrolExpandable = true;

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private mapSettingsService: MapSettingsService,
    private robotService: RobotService,
    private rosLibService: RoslibService,
    private mapPointService: MapPointService,
    private mapConfigurationService: MapConfigurationService
  ) {}

  ngOnInit(): void {
    this.listenMapConfigurationAdapter();
    this.listenChangeNiche();
    this.listenMapRendered();

    const mapId: number = this.route.snapshot.params['id'];
    const robotId = this.route.snapshot.params['robotId'];

    forkJoin(
      this.mapService.getById(mapId),
      this.mapConfigurationService.getAll('map=' + mapId),
      this.robotService.getById(robotId)
    ).subscribe(
      (
        result: [map: Map, mapConfigurations: MapConfiguration[], robot: Robot]
      ) => {
        this.mapAdapter = new MapAdapter(result[0]);
        this.mapConfigurationAdapters = result[1].map(
          (mapConfiguration: MapConfiguration) =>
            new MapConfigurationAdapter(mapConfiguration)
        );
        this.robotAdapter = new RobotAdapter(result[2], this.rosLibService);

        const activeMapConfiguration: MapConfigurationAdapter | undefined =
          this.mapConfigurationAdapters.find(
            (mapConfigurationAdapter: MapConfigurationAdapter) =>
              mapConfigurationAdapter.mapConfiguration.id ===
              this.robotAdapter?.robot.activeMapConfiguration?.id
          );

        if (activeMapConfiguration) {
          this.currentMapConfigurationAdapter = activeMapConfiguration;
        } else {
          this.currentMapConfigurationAdapter = new MapConfigurationAdapter(
            this.mapConfigurationAdapters[0].mapConfiguration
          );
        }
        this.mapSettingsService.updateConfigurations(
          this.mapConfigurationAdapters
        );
      }
    );
  }

  /**
   * Ecoute le chargement de configuration
   *
   * On supprime tous les points de la carte
   *
   * @private
   */
  private listenMapConfigurationAdapter(): void {
    this.mapSettingsService.mapConfiguration$.subscribe(
      (mapConfigurationAdapter: MapConfigurationAdapter | null) => {
        if (mapConfigurationAdapter) {
          this.currentMapConfigurationAdapter = mapConfigurationAdapter;
          this.mapPointService.removeAll();
          if (mapConfigurationAdapter.mapConfiguration.niche) {
            this.addNiche(mapConfigurationAdapter);
          }
          this.loadData(mapConfigurationAdapter);
        }
      }
    );
  }

  public listenChangeNiche(): void {
    this.mapPointService
      .onAdd()
      .pipe(
        filter((mapPoint: MapPoint) => mapPoint.group === MapPointType.NICHE)
      )
      .subscribe((mapPoint: MapPoint) => {
        if (this.currentMapConfigurationAdapter) {
          this.currentMapConfigurationAdapter.mapConfiguration.niche =
            mapPoint.pose;

          this.mapConfigurationService
            .update(
              this.currentMapConfigurationAdapter.mapConfiguration.id,
              this.currentMapConfigurationAdapter.mapConfiguration
            )
            .subscribe(
              () => {},
              () => {}
            );
        }
      });
  }

  /**
   * Evènement d'écoute indiquant quand la carte est rendue
   *
   * @private
   */
  private listenMapRendered(): void {
    this.mapPointService.onMapRendered().subscribe(() => {
      if (this.currentMapConfigurationAdapter) {
        this.addNiche(this.currentMapConfigurationAdapter);
      }
    });
  }

  /**
   * Chargement des données de la configuration
   *
   * @param mapConfigurationAdapter la configuration
   * @private
   */
  private loadData(mapConfigurationAdapter: MapConfigurationAdapter): void {
    if (mapConfigurationAdapter.mapConfiguration.checkpoints) {
      this.checkpointAdapters =
        mapConfigurationAdapter.mapConfiguration.checkpoints.map(
          (checkpoint: Checkpoint) => new CheckpointAdapter(checkpoint)
        );
    }

    if (mapConfigurationAdapter.mapConfiguration.strategies) {
      this.strategyAdapters =
        mapConfigurationAdapter.mapConfiguration.strategies.map(
          (strategy: Strategy) => new StrategyAdapter(strategy)
        );
    }

    if (mapConfigurationAdapter.mapConfiguration.patrols) {
      this.patrolAdapters =
        mapConfigurationAdapter.mapConfiguration.patrols.map(
          (patrol: Patrol) => new PatrolAdapter(patrol)
        );
    }
  }

  /**
   * Affichage de la niche
   *
   * @private
   */
  private addNiche(mapConfigurationAdapter: MapConfigurationAdapter): void {
    if (
      mapConfigurationAdapter &&
      mapConfigurationAdapter.mapConfiguration.niche
    ) {
      const niche: MapPoint = new MapPoint(
        mapConfigurationAdapter.mapConfiguration.niche
      );
      niche.uid = 'niche';
      niche.displayObject = Utils.createNiche();
      niche.group = MapPointType.NICHE;
      this.mapPointService.addOrUpdatePoint(niche);
    }
  }

  /**
   * Changement d'onglet
   *
   * Si on clique sur l'onglet des paramétrages de patrouilles avec l'index 0 on cache les points de contrôle de toutes les stratégies sur la carte et on autorise le développement de toutes les patrouilles
   * Si on clique sur l'onglet des paramétrages de stratégies avec l'index 1 on cache les points de contrôle de toutes les patrouilles sur la carte et on interdit le développement de toutes les patrouilles
   *
   * @param event l'évènement de changement d'onglet
   */
  public onSelectedTagChange(event: MatTabChangeEvent) {
    if (
      this.currentMapConfigurationAdapter &&
      this.currentMapConfigurationAdapter.mapConfiguration &&
      this.currentMapConfigurationAdapter.mapConfiguration.patrols
    ) {
      this.currentMapConfigurationAdapter.mapConfiguration.patrols.forEach(
        (patrol: Patrol) => {
          if (patrol.id) {
            this.mapPointService.setVisiblePoint(patrol.id.toString(), false);
          }
        }
      );
    }

    if (event.index === 1) {
      this.patrolExpandable = false;
    } else if (event.index === 0) {
      this.patrolExpandable = true;
    }
  }
}
