<div fxLayout="column" fxLayoutGap="20px">
  <div>
    <webclient-robot-toolbar></webclient-robot-toolbar>
  </div>

  <div fxLayout="row" fxLayoutGap="20px">

    <div fxFlex="40">
      <mat-card fxLayout="column">
        <div>
          <ngx-rodotic-viewer *ngIf="mapAdapter" [mapInfo]="mapAdapter.mapInfo"></ngx-rodotic-viewer>
          <webclient-map-control [robotAdapter]="robotAdapter"></webclient-map-control>
        </div>
      </mat-card>
    </div>

    <div fxFlex="60" fxLayoutGap="20px">
      <mat-card fxLayout="column">
        <mat-card-content fxLayout="column">
          <webclient-map-configuration-toolbar [selectedMapConfigurationAdapter]="currentMapConfigurationAdapter" [mapAdapter]="mapAdapter" [robotAdapter]="robotAdapter"></webclient-map-configuration-toolbar>

          <mat-tab-group (selectedTabChange)="onSelectedTagChange($event)">
            <mat-tab label="{{ 'map.settings.patrol' | translate }}">
              <div cdkDropListGroup fxLayout="row" fxLayout.lg="column" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="40px">
                <webclient-checkpoint-list [checkpointAdapters]="checkpointAdapters" fxFlex="45"></webclient-checkpoint-list>
                <webclient-patrol-list [patrolAdapters]="patrolAdapters" [expandable]="patrolExpandable" fxFlex="55"></webclient-patrol-list>
              </div>
            </mat-tab>

            <mat-tab label="{{ 'map.settings.strategy' | translate }}">
              <webclient-strategy-list [strategyAdapters]="strategyAdapters" [patrolAdapters]="patrolAdapters" [editable]="true" [canReorder]="true" [canCommand]="true" [canAction]="true"></webclient-strategy-list>
              <webclient-strategy-calendar [possibleEvent]="['Event 1', 'Event 2']" [strategyAdapters]="strategyAdapters"></webclient-strategy-calendar>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

</div>
