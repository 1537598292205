import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'webclient-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'client';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService
  ) {
    this.matIconRegistry.addSvgIcon(
      'alf31',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/alf31.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'eye-on',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/eye-on.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'eye-off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/eye-off.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'concierge',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/concierge.svg'
      )
    );

    this.translateService.addLangs(['fr', 'en']);
    const browserLang = 'fr'; // window.navigator.languages ? window.navigator.languages[0] : null;
    this.translateService.setDefaultLang(browserLang);
    this.translateService.use(browserLang);
  }
}
