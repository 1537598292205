<webclient-entity-form
  [urlList]="'/pages/admin/trigger/list/'"
  [entity]="trigger"
  [form]="form"
  [createTitle]="'trigger.create'"
  [editTitle]="'trigger.edit'"
  [icon]="'bolt'"
  (onCreateEntity)="onCreate($event)"
  (onEditEntity)="onEdit($event)"
>
  <form
    [formGroup]="form"
    *ngIf="form"
    fxLayout="column"
    fxLayoutGap="20px"
    class="admin-form"
  >
    <!-- Champ nom -->
    <div fxLayout="row" fxLayoutGap="10px">
      <div
        fxLayout="row"
        fxLayout.sm="column"
        fxLayout.xs="column"
        fxLayoutGap="20px"
        fxFlex="100"
      >
        <mat-form-field fxFlex>
          <mat-icon matPrefix>bolt</mat-icon>
          <input
            matInput
            type="text"
            name="name"
            required="required"
            placeholder="{{ 'trigger.name' | translate }}"
            formControlName="name"
          />
          <mat-error *ngFor="let validation of triggerValidationMessages.name">
            <ng-container
              *ngIf="
                formControl?.name?.hasError(validation.type) &&
                (formControl?.name?.dirty || formControl?.name?.touched)
              "
            >
              {{ validation.message | translate }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Champ valeur -->
    <div fxLayout="row" fxLayoutGap="10px">
      <div
        fxLayout="row"
        fxLayout.sm="column"
        fxLayout.xs="column"
        fxLayoutGap="20px"
        fxFlex="100"
      >
        <mat-form-field fxFlex>
          <mat-icon matPrefix>diamond</mat-icon>
          <input
            matInput
            type="text"
            name="value"
            required="required"
            placeholder="{{ 'trigger.value' | translate }}"
            formControlName="value"
          />
          <mat-error *ngFor="let validation of triggerValidationMessages.value">
            <ng-container
              *ngIf="
                formControl?.value?.hasError(validation.type) &&
                (formControl?.value?.dirty || formControl?.value?.touched)
              "
            >
              {{ validation.message | translate }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Champ état d'activation -->
    <mat-slide-toggle formControlName="hasActivatedState">
      {{ 'trigger.activationState' | translate }}
    </mat-slide-toggle>

    <!-- Champ état monostable -->
    <mat-slide-toggle formControlName="hasMonostableState">
      {{ 'trigger.monostableState' | translate }}
    </mat-slide-toggle>
  </form>
</webclient-entity-form>
