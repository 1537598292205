import { StrategyDTO } from '../dto/strategy-dto';
import { EntityTransformer } from './entity-transformer';
import { PatrolCheckpointTransformer } from './patrol-checkpoint-transformer';
import { PatrolCheckpointDto } from '../dto/patrol-checkpoint-dto';
import { TimeslotDto } from '../dto/timeslot-dto';
import { Timeslot } from '../../../../api/models/timeslot';
import { PatrolCheckpoint } from '../../../../api/models/patrol-checkpoint';
import { StrategyMode } from '../../../enums/strategy-mode.enum';
import { TimeslotAdapter } from '../../entities/timeslot-adapater';
import { Strategy } from '../../../../api/models/strategy';

export class StrategyTransformer
  implements EntityTransformer<Strategy, StrategyDTO>
{
  to(from: Strategy): StrategyDTO {
    const strategyDTO: StrategyDTO = new StrategyDTO();

    strategyDTO.id = from.id;
    strategyDTO.random = from.randomMode;
    strategyDTO.cycles = from.rotationNumber;
    strategyDTO.patrol_duration = from.patrolDuration;
    strategyDTO.pause_duration = from.breakDuration;
    strategyDTO.web_order = true;
    strategyDTO.type = 20;

    if (
      from.patrol &&
      from.patrol.checkpoints &&
      from.patrol.checkpoints.length > 0 &&
      from.patrol.checkpoints[0].checkpoint
    ) {
      strategyDTO.starting_point = from.patrol.checkpoints[0].checkpoint.id;
    }

    if (from.patrol && from.patrol.checkpoints) {
      from.patrol.checkpoints.sort(
        (pc1: PatrolCheckpoint, pc2: PatrolCheckpoint) =>
          pc1.position && pc2.position && pc1.position > pc2.position ? 1 : -1
      );

      from.patrol.checkpoints.forEach((patrolCheckpoint: PatrolCheckpoint) => {
        const checkpointTransformer: PatrolCheckpointTransformer =
          new PatrolCheckpointTransformer();
        strategyDTO.patrol_list.push(
          checkpointTransformer.to(patrolCheckpoint) as PatrolCheckpointDto
        );
      });
    }

    if (
      from.mode === StrategyMode.TRIGGERED &&
      from.triggerItems[0] &&
      from.triggerItems[0].launcher
    ) {
      strategyDTO.plannified = false;

      if (from.triggerItems[0].launcher.value === 'BIE_INPUT_0') {
        strategyDTO.BIE_input0 = true;
        strategyDTO.monostable0 = from.triggerItems[0].monostableState;
        strategyDTO.state_on0 = from.triggerItems[0].activationState;
      } else {
        if (from.triggerItems[0].launcher.value === 'BIE_INPUT_1') {
          strategyDTO.BIE_input1 = true;
          strategyDTO.monostable1 = from.triggerItems[0].monostableState;
          strategyDTO.state_on1 = from.triggerItems[0].activationState;
        } else {
          if (from.triggerItems[0].launcher.value === 'BIE_INPUT_2') {
            strategyDTO.BIE_input2 = true;
            strategyDTO.monostable2 = from.triggerItems[0].monostableState;
            strategyDTO.state_on2 = from.triggerItems[0].activationState;
          } else {
            if (from.triggerItems[0].launcher.value === 'BIE_INPUT_3') {
              strategyDTO.BIE_input3 = true;
              strategyDTO.monostable3 = from.triggerItems[0].monostableState;
              strategyDTO.state_on3 = from.triggerItems[0].activationState;
            }
          }
        }
      }
    } else {
      strategyDTO.plannified = true;

      if (from.timeslots) {
        from.timeslots.forEach((timeslot: Timeslot) => {
          const timeslotAdapter: TimeslotAdapter = new TimeslotAdapter(
            timeslot,
            '',
            ''
          );
          const timeslotDTO: TimeslotDto = new TimeslotDto();

          timeslotDTO.start_day = timeslotAdapter.start.getDay();
          timeslotDTO.stop_day = timeslotAdapter.end?.getDay();

          const startTime: Date = timeslotAdapter.start;
          const endTime: Date | undefined = timeslotAdapter.end;
          let startMinutes: number;
          let endMinutes: number | undefined;

          if (isNaN(startTime.getTime())) {
            startMinutes = timeslotAdapter.start.getMinutes();
          } else {
            startMinutes = startTime.getMinutes() + startTime.getHours() * 60;
          }

          if (endTime && isNaN(endTime.getTime())) {
            endMinutes = timeslotAdapter.end?.getMinutes();
          } else if (endTime) {
            endMinutes = endTime.getMinutes() + endTime.getHours() * 60;
          }

          timeslotDTO.start_time = startMinutes;
          timeslotDTO.stop_time = endMinutes;

          strategyDTO.timeslot_list.push(timeslotDTO);
        });
      }
    }

    return strategyDTO;
  }
}
